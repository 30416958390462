import { CloseOutlined, UploadOutlined } from '@mui/icons-material';
import { Button, Image, Input, InputNumber, Select, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import '../../../src/global.css';
import Header from '../../components/Header';
import './style.css';

import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import Modal from '../../components/Modal';
import { useGlobalContext } from '../../context/GlobalContext';
import { ICompetenciaLearnCleanDTO, IHabilidadeLearnCleanDTO, IInsigniaDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useCompenteciaService } from '../../services/competencias.service';
import { useHabilidadeService } from '../../services/habilidades.service';
import { useInsigniaService } from '../../services/insignia.service';
import { useNotificationService } from '../../services/notification.service';
import { token } from '../../util/store';

const InsiginiaCreate: React.FunctionComponent = () => {
	const { setIsGlobalLoading } = useGlobalContext();
	const notification = useNotificationService();
	const insigniaService = useInsigniaService();
	const habilidadeService = useHabilidadeService();
	const competenciaService = useCompenteciaService();
	const [insignia, setInsignia] = useState<IInsigniaDTO>();
	const [habilidade, setHabilidade] = useState<IHabilidadeLearnCleanDTO[]>();
	const [competencia, setCompetencia] = useState<ICompetenciaLearnCleanDTO[]>();
	const history = useHistory();
	const [isOpen, setIsOpen] = useState(false);
	const [predicate] = useState<Predicate>(new Predicate());
	const { idInsignia } = useParams<any>();

	const findInsignia = () => {
		setIsGlobalLoading(true);
		insigniaService
			.findById({ id: idInsignia })
			.then((response) => {
				setInsignia(response.data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const findHabilidade = () => {
		setIsGlobalLoading(true);
		habilidadeService
			.findList(predicate)
			.then((response) => {
				setHabilidade(response.data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const findCompetencia = () => {
		setIsGlobalLoading(true);
		competenciaService
			.findList(predicate)
			.then((response) => {
				setCompetencia(response.data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	useEffect(() => {
		if (idInsignia) {
			findInsignia();
		}
		findHabilidade();
		findCompetencia();
		return;
	}, []);

	const submit = () => {
		setIsGlobalLoading(true);
		if (!idInsignia) {
			insigniaService
				.create(insignia)
				.then(() => {
					notification({
						description: `insignia cadastrada com sucesso!`,
						type: 'success',
						message: 'Sucesso!',
					});
					history.push('/insignia');
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => setIsGlobalLoading(false));
		} else {
			insigniaService
				.patch(insignia)
				.then(() => {
					notification({
						description: `insignia editada com sucesso!`,
						type: 'success',
						message: 'Sucesso!',
					});
					history.push('/insignia');
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => setIsGlobalLoading(false));
		}
	};

	const submitValidationFields = () => {
		if (!insignia?.nome) {
			notification({
				description: `Por favor, preencha o campo nome da insígnia.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!insignia?.habilidade.id) {
			notification({
				description: `Por favor, preencha o campo habilidade.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!insignia?.competencia.id) {
			notification({
				description: `Por favor, preencha o campo competencia.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!insignia?.icone) {
			notification({
				description: `Por favor, selecione uma imagem para o icone da insígnia corretamente.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		submit();
	};

	const onChange = ({ file }: UploadChangeParam) => {
		const { status } = file;

		if (status === 'done') {
			notification({
				description: `${file.name} upload feito com sucesso`,
				type: 'success',
				message: 'Sucesso!',
			});
			const response = file.response;
			setInsignia({ ...insignia, icone: response });
		} else if (status === 'error') {
			notification({
				description: `${file.name} falha ao fazer o upload do arquivo `,
				type: 'error',
				message: 'Erro!',
			});
		} else if (status === 'removed') {
			setInsignia({ ...insignia, icone: null });
			notification({
				description: `${file.name} removida com sucesso`,
				type: 'success',
				message: 'Sucesso!',
			});
		}
	};

	const beforeUpload = (file: UploadFile) => {
		const isValid =
			(file !== undefined && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/x-icon')) || file.type === 'image/webp';
		if (!isValid) {
			file.status = 'error';
			notification({
				description: `Apenas imagens PNG, JPG e WEBP sao permitidas.`,
				type: 'error',
				message: 'Erro!',
			});
			return false;
		}
		if (file.size > 5 * 1024 * 1024) {
			file.status = 'error';
			notification({
				description: `O tamanho do arquivo deve ser inferior a 5MB.`,
				type: 'error',
				message: 'Erro!',
			});
			return false;
		}
		return isValid;
	};
	return (
		<div className="container-fondo">
			<Header />
			<div className="franquia" style={{ left: 0 }}>
				<div className="user-row">
					<div className="card-form">
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<h3 id="taxas-h3" style={{ marginBottom: 10 }}>
								Insira informações para {idInsignia ? 'edição' : 'criação'} de Insígnia
							</h3>
							<Button onClick={() => setIsOpen(true)} id="cancelar" className="button-close" icon={<CloseOutlined />} />
						</div>
						<div className="input-box">
							<label>Nome da insígnia</label>
							<Input
								defaultValue={insignia?.nome}
								value={insignia?.nome}
								id="DsConteudo"
								maxLength={100}
								placeholder="Ex:. COMUNICADOR GAMER"
								onChange={(e) => setInsignia({ ...insignia, nome: e.target.value })}
							/>
						</div>
						<div className="input-box">
							<label>Habilidade</label>
							<Select
								allowClear
								placeholder="Selecione"
								defaultValue={insignia?.habilidade?.id}
								value={insignia?.habilidade?.id}
								style={{ width: '100%' }}
								onChange={(e) =>
									insignia &&
									setInsignia({
										...insignia,
										habilidade: { ...insignia.habilidade, id: e },
									})
								}
							>
								{habilidade &&
									habilidade.map((habilidade) => (
										<Select.Option key={habilidade.id} value={habilidade.id}>
											{habilidade.descricao}
										</Select.Option>
									))}
							</Select>
						</div>
						<div className="input-box">
							<label>Competência</label>
							<Select
								allowClear
								placeholder="Selecione"
								defaultValue={insignia?.competencia?.id}
								value={insignia?.competencia?.id}
								style={{ width: '100%' }}
								onChange={(e) =>
									insignia &&
									setInsignia({
										...insignia,
										competencia: { ...insignia.competencia, id: e },
									})
								}
							>
								{competencia &&
									competencia.map((competencia) => (
										<Select.Option key={competencia.id} value={competencia.id}>
											{competencia.descricao}
										</Select.Option>
									))}
							</Select>
						</div>
						<div className="input-box">
							<label>Nota Mínima na Habilidade</label>
							<InputNumber
								value={insignia?.notaCompetencia}
								defaultValue={insignia?.notaCompetencia}
								placeholder="Ex.: 2"
								onChange={(e) => setInsignia({ ...insignia, notaCompetencia: e })}
								min={1}
							/>
						</div>
						<div className="input-box">
							<label>Nota Mínima na Competência</label>
							<InputNumber
								value={insignia?.notaHabilidade}
								defaultValue={insignia?.notaHabilidade}
								placeholder="Ex.: 5"
								onChange={(e) => setInsignia({ ...insignia, notaHabilidade: e })}
								min={1}
							/>
						</div>
						<div className="input-box">
							<label>Recompensa (coins)</label>
							<InputNumber
								value={insignia?.coins}
								defaultValue={insignia?.coins}
								placeholder="Ex.: 100"
								onChange={(e) => setInsignia({ ...insignia, coins: e })}
								min={1}
							/>
						</div>
						<div className="input-box" style={{ minWidth: '100%', minHeight: '100px' }}>
							<label>Icone</label>
							<div style={{ textAlign: 'center', width: '100%' }}>
								<div>{insignia?.icone && <Image src={insignia.icone} alt="Ícone da insignia" width={200} />}</div>
								<Upload
									headers={{ Authorization: `Bearer ${token()}` }}
									action={`${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/upload/link`}
									onChange={onChange}
									maxCount={1}
									beforeUpload={beforeUpload}
								>
									<Button
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											marginBottom: '1rem',
										}}
									>
										<UploadOutlined style={{ fontSize: '20px', marginBottom: '4px' }} />
										<span>Clique para fazer upload da imagem</span>
									</Button>
								</Upload>
							</div>
						</div>
						{/* <div className="input-box">
							<label>Ordem de Exibição</label>
							<InputNumber
								defaultValue={insignia?.nrOrdem}
								value={insignia?.nrOrdem}
								id="nrOrdem"
								placeholder="0"
								onChange={(e) => setInsignia({ ...insignia, nrOrdem: e })}
							/>
						</div> */}
						<div className="botoes" style={{ marginTop: 10 }}>
							<Link to={`/insignia`}>
								<Button className="button-second" id="voltar" style={{ width: 345, marginRight: 10 }}>
									Voltar
								</Button>
							</Link>
							<Button style={{ width: 345 }} type="primary" id="proximo" className="button-primary" onClick={submitValidationFields}>
								Salvar
							</Button>
						</div>
					</div>
				</div>
			</div>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a operação?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2={`/insignia`}
			/>
		</div>
	);
};

export default InsiginiaCreate;
