import { CloseOutlined, UploadOutlined } from '@mui/icons-material';
import { Button, Image, Input, InputNumber, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import '../../../src/global.css';
import Header from '../../components/Header';
import './style.css';

import Modal from '../../components/Modal';
import { useGlobalContext } from '../../context/GlobalContext';
import { IBrasaoDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { useBrasaoService } from '../../services/brasao.service';
import { useNotificationService } from '../../services/notification.service';
import { token } from '../../util/store';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';

const BrasaoCreate: React.FunctionComponent = () => {
	const { setIsGlobalLoading } = useGlobalContext();
	const notification = useNotificationService();
	const brasaoService = useBrasaoService();
	const history = useHistory();
	const [isOpen, setIsOpen] = useState(false);
	const [brasao, setBrasao] = useState<IBrasaoDTO>();
	const [predicate] = useState<Predicate>(new Predicate());
	const { idBrasao } = useParams<any>();

	const findBrasao = () => {
		setIsGlobalLoading(true);
		brasaoService
			.findById({ id: idBrasao })
			.then((response) => {
				console.log(response.data);

				setBrasao(response.data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	useEffect(() => {
		if (idBrasao) {
			findBrasao();
		}
		return;
	}, []);

	const submit = () => {
		setIsGlobalLoading(true);
		if (!idBrasao) {
			brasaoService
				.create(brasao)
				.then(() => {
					notification({
						description: `Brasão cadastrado com sucesso!`,
						type: 'success',
						message: 'Sucesso!',
					});
					history.push('/brasao');
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => setIsGlobalLoading(false));
		} else {
			brasaoService
				.patch(brasao)
				.then(() => {
					notification({
						description: `Brasão editado com sucesso!`,
						type: 'success',
						message: 'Sucesso!',
					});
					history.push('/brasao');
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => setIsGlobalLoading(false));
		}
	};

	const submitValidationFields = () => {
		if (!brasao?.nome) {
			notification({
				description: `Por favor, preencha o campo nome do brasao.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		if (!brasao?.icone) {
			notification({
				description: `Por favor, preencha o campo icone do brasão.`,
				type: 'warning',
				message: 'Atenção!',
			});
			return;
		}
		submit();
	};

	const onChange = ({ file }: UploadChangeParam) => {
		const { status } = file;

		if (status === 'done') {
			notification({
				description: `${file.name} upload feito com sucesso!`,
				type: 'success',
				message: 'Sucesso!',
			});
			const response = file.response;
			setBrasao({ ...brasao, icone: response });
		} else if (status === 'error') {
			notification({
				description: `${file.name} falha ao fazer o upload do arquivo`,
				type: 'error',
				message: 'Erro!',
			});
		} else if (status === 'removed') {
			setBrasao({ ...brasao, icone: null });
		}
	};

	const beforeUpload = (file: UploadFile) => {
		const isValid =
			file !== undefined && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/x-icon' || file.type === 'image/webp');
		if (!isValid) {
			file.status = 'error';
			notification({
				description: `Apenas arquivos PNG, JPG e ICO são permitidos.`,
				type: 'error',
				message: 'Erro!',
			});
			return false;
		}
		if (file.size > 5 * 1024 * 1024) {
			file.status = 'error';
			notification({
				description: `O tamanho do arquivo deve ser inferior a 5MB.`,
				type: 'error',
				message: 'Erro!',
			});
			return false;
		}
		return isValid;
	};
	return (
		<div className="container-fondo">
			<Header />
			<div className="franquia" style={{ left: 0 }}>
				<div className="user-row">
					<div className="card-form">
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<h3 id="taxas-h3" style={{ marginBottom: 10 }}>
								Insira informações para {idBrasao ? 'edição' : 'cadastro'} de brasão
							</h3>
							<Button onClick={() => setIsOpen(true)} id="cancelar" className="button-close" icon={<CloseOutlined />} />
						</div>
						<div className="input-box">
							<label>Nome do brasão</label>
							<Input
								defaultValue={brasao && brasao?.nome}
								value={brasao && brasao?.nome}
								id="DsConteudo"
								maxLength={100}
								placeholder="OURO"
								onChange={(e) => setBrasao({ ...brasao, nome: e.target.value })}
							/>
						</div>
						<div className="input-box">
							<label>Recompensa (coins)</label>
							<InputNumber
								value={brasao?.coins}
								defaultValue={brasao?.coins}
								id="NrInsignia"
								min={1}
								onChange={(e) => setBrasao({ ...brasao, coins: e })}
								placeholder="Ex.: 10"
							/>
						</div>
						<div className="input-box">
							<label>Número de Insígnias disponíveis</label>
							<InputNumber
								value={brasao?.insignia}
								defaultValue={brasao?.insignia}
								id="insignia"
								min={0}
								onChange={(e) => setBrasao({ ...brasao, insignia: e })}
								placeholder="Ex.: 1"
							/>
						</div>
						<div className="input-box" style={{ minWidth: '100%', minHeight: '100px' }}>
							<label>Ícone</label>
							<div style={{ textAlign: 'center', width: '100%' }}>
								<div>{brasao?.icone && <Image src={brasao?.icone} alt="Ícone da medalha" width={200} />}</div>
								<Upload
									headers={{ Authorization: `Bearer ${token()}` }}
									action={`${process.env.REACT_APP_HAPPYCODE_PUBLIC_BASE_URL_API}arquivo/upload/link`}
									onChange={onChange}
									maxCount={1}
									beforeUpload={beforeUpload}
								>
									<Button
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											marginBottom: '1rem',
										}}
									>
										<UploadOutlined style={{ fontSize: '20px', marginBottom: '4px' }} />
										<span>Clique para fazer upload da imagem</span>
									</Button>
								</Upload>
							</div>
						</div>
						<div className="botoes" style={{ marginTop: 10 }}>
							<Link to={`/brasao`}>
								<Button className="button-second" id="voltar" style={{ width: 345, marginRight: 10 }}>
									Voltar
								</Button>
							</Link>
							<Button style={{ width: 345 }} type="primary" id="proximo" className="button-primary" onClick={submitValidationFields}>
								Salvar
							</Button>
						</div>
					</div>
				</div>
			</div>
			<Modal
				open={isOpen}
				onClose={() => setIsOpen(false)}
				title="Você deseja cancelar a operação?"
				subtitle="Todos os dados serão perdidos, e essa ação não pode ser desfeita."
				textButton1="Não"
				textButton2="Sim, Cancelar"
				styleButton1="button-line"
				styleButton2="button-danger"
				link2={`/brasao`}
			/>
		</div>
	);
};

export default BrasaoCreate;
