import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';
import { IDashSectionDTO, IUnidadePessoaCleanDTO } from '../../../models/happy-code-api.model';
import { useDashSectionService } from '../../../services/dash-section.service';
import { Predicate } from '../../../models/predicate.model';
import { ReportCard } from '../../../types/report-card.type';
import { useUsuarioService } from '../../../services/usuario.service';
import { PerfilEnum } from '../../../enum/perfil.enum';
import { FuncaoEnum } from '../../../enum/funcao.enum';

export default function useFindPowerBiDashboards() {
	const { setIsGlobalLoading, usuario } = useGlobalContext();

	const predicate = useMemo(() => new Predicate(), []);

	const { findList } = useDashSectionService();
	const { findByUsuarioIdTipoUnidadePessoa } = useUsuarioService();

	const [dashboards, setDashboards] = useState<IDashSectionDTO[]>([]);

	const hasFilter = (unidadePessoa: IUnidadePessoaCleanDTO) => {
		if (usuario.perfil === PerfilEnum.SEDE && usuario.funcao === FuncaoEnum.ADMINISTRADOR_CODIGO) {
			return false;
		}

		if (unidadePessoa.tipoUnidadePessoaId === 2 && usuario.perfil === PerfilEnum.FRANQUIA) {
			return false;
		}
		return true;
	};

	const findDashboards = async () => {
		setIsGlobalLoading(true);

		const pessoaCodigo = await findByUsuarioIdTipoUnidadePessoa(usuario?.id);
		const unidadePessoa = pessoaCodigo.data;

		try {
			predicate.addOption('ativo', true);
			const response = await findList(predicate);
			const relatorios = response.data;

			if (hasFilter(unidadePessoa)) {
				const filterData = relatorios.filter((r) => r.dashReport.id !== 8);
				setDashboards(filterData);
				return;
			}
			setDashboards(relatorios);
		} catch (error) {
			console.log(error);
		} finally {
			setIsGlobalLoading(false);
		}
	};

	useEffect(() => {
		const controller = new AbortController();
		if (usuario.id) findDashboards();

		return () => controller.abort();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [usuario]);

	const buildDashboardDataToReportScreen = useCallback((): ReportCard[] => {
		return dashboards.map(({ dashReport, codigoPbi, nome }, index) => {
			return {
				id: index,
				title: nome,
				link: `/relatorio/${dashReport.codigoPbi}/${codigoPbi}`,
			};
		});
	}, [dashboards]);

	return {
		dashboards,
		buildDashboardDataToReportScreen,
	};
}
