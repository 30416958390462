import { DatePicker, Select } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import NewMenu from '../../../../components/NewMenu';
import { useGlobalContext } from '../../../../context/GlobalContext';
import { IRelatorioNotas, IStatusENotas } from '../../../../models/relatorio.model';
import { useEnotasService } from '../../../../services/enotas.service';
import { formatMoney } from '../../../../util/format';

const { RangePicker } = DatePicker;

function RelNotasFiscal() {
    const { setIsGlobalLoading, unidade } = useGlobalContext();
    const [notas, setNotas] = useState<IRelatorioNotas>();
    const relatorioService = useEnotasService();
    const [status, setStatus] = useState<IStatusENotas>(null);
    const [dataVencimentoInicio, setDataVencimentoInicio] = useState<moment.Moment>(null);
    const [dataVencimentoFim, setDataVencimentoFim] = useState<moment.Moment>(null);

    useEffect(() => {
        if (status === null) {
            setStatus('Autorizada');
        }
        if (
            unidade &&
            unidade.id &&
            unidade.identificadorEnotas &&
            status !== null &&
            dataVencimentoFim &&
            dataVencimentoInicio &&
            dataVencimentoInicio.isValid() &&
            dataVencimentoFim.isValid()
        ) {
            setIsGlobalLoading(true);
            const identificador = unidade.identificadorEnotas;
            const formattedDataVencimentoInicio = dataVencimentoInicio.format('YYYY-MM-DD');
            const formattedDataVencimentoFim = dataVencimentoFim.format('YYYY-MM-DD');
            relatorioService
                .relatorioNotasPorHub(identificador, 0,status,formattedDataVencimentoInicio, formattedDataVencimentoFim)
                .then((response) => setNotas(response.data))
                .catch((erro) => console.log(erro))
                .finally(() => setIsGlobalLoading(false));
        }
    }, [status, dataVencimentoInicio, dataVencimentoFim]);


    function onChangeStatus(status) {
        if (status === 'Autorizada') {
            setStatus('Autorizada')
        }
        if (status === 'AguardandoAutorizacao') {
            setStatus('AguardandoAutorizacao')
        }
        if (status === 'AutorizadaAguardandoGeracaoPDF') {
            setStatus('AutorizadaAguardandoGeracaoPDF')
        }
        if (status === 'Cancelada') {
            setStatus('Cancelada')
        }
        if (status === 'CancelamentoNegado') {
            setStatus('CancelamentoNegado')
        }
        if (status === 'AutorizacaoSolicitada') {
            setStatus('AutorizacaoSolicitada')
        }
        if (status === 'EmProcessoDeAutorizacao') {
            setStatus('EmProcessoDeAutorizacao')
        }
        if (status === 'SolicitandoAutorizacao') {
            setStatus('SolicitandoAutorizacao')
        }
        if (status === 'Negada') {
            setStatus('Negada')
        }
    }

    return (
        <div className="container">
            <NewMenu selecionado={1} />
            <div
                className="dashboard"
                style={{ height: '100vh', overflowY: 'scroll', paddingTop: 50 }}
            >
                <h3 className="title">Notas Fiscais de serviço</h3>
                <div className='filter' style={{ display: 'flex', gap: '3%' }}>
                    <div className="statusPicker">
                        <span>Status</span>
                        <Select defaultValue="Autorizada" size="middle" onChange={onChangeStatus} id="status-select" style={{ display: 'flex', width: '110%'}}>
                            <Select.Option value="Autorizada">Autorizada  </Select.Option>
                            <Select.Option value="AguardandoAutorizacao">
                                Aguardando Autorizacao
                            </Select.Option>
                            <Select.Option value="AutorizadaAguardandoGeracaoPDF">
                                Autorizada Aguardando Geracao PDF
                            </Select.Option>
                            <Select.Option value="Cancelada">Cancelada  </Select.Option>
                            <Select.Option value="CancelamentoNegado">
                                Cancelamento Negado
                            </Select.Option>
                            <Select.Option value="AutorizacaoSolicitada">
                                Autorizacao Solicitada
                            </Select.Option>
                            <Select.Option value="EmProcessoDeAutorizacao">
                                Em Processo De Autorizacao
                            </Select.Option>
                            <Select.Option value="SolicitandoAutorizacao">
                                Solicitando Autorizacao
                            </Select.Option>
                            <Select.Option value="Negada">Negada</Select.Option>
                        </Select>
                    </div>
                    <div className='datePicker'>
                        <span>Data de Emissão</span>
                        <RangePicker
                            onChange={(values) => {
                                setDataVencimentoInicio(values && values[0]);
                                setDataVencimentoFim(values && values[1]);
                            }}
                            format={'DD-MM-YYYY'}
                            style={{ display: 'flex' }}
                        />
                    </div>
                </div>
                {unidade.identificadorEnotas ? (
                    <table id="tableConciliacao">
                        <thead>
                            <tr id="top">
                                <td id="colunas">Número</td>
                                {status !== 'Autorizada' ? (
                                    <td id="colunas">Motivo Falha</td>
                                ) : null}
                                <td id="colunas">Valor Total</td>
                                <td id="colunas">Data Emissão</td>
                                <td id="colunas">Cliente</td>
                                <td id="colunas">E-mail</td>
                                {status == 'Autorizada' ? <td id="colunas">Baixar</td> : null}
                            </tr>
                        </thead>
                        <tbody>
                            {notas?.data && notas.data.map((nota, index) => (
                                <tr key={index}>
                                    <td id="colunas">{nota.numero}</td>
                                    {status !== 'Autorizada' ? (
                                        <td id="colunas">{nota.motivoStatus}</td>
                                    ) : null}
                                    <td id="colunas">{formatMoney(nota.valorTotal)}</td>
                                    <td id="colunas">
                                        {moment(nota.dataCriacao).format('DD/MM/YYYY')}
                                    </td>
                                    <td id="colunas">{nota?.cliente?.nome}</td>
                                    <td id="colunas">{nota?.cliente?.email}</td>
                                    {status == 'Autorizada' ? (
                                        <td id="colunas">
                                            <a
                                                rel="noopener noreferrer"
                                                href={nota.linkDownloadPDF}
                                                target="_blank"
                                            >
                                                Download
                                            </a>
                                        </td>
                                    ) : null}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <>
                        A emissão automática de notas fiscais via Happy Space ainda não está habilitada para o seu hub. Por favor,
                        contate o suporte!
                    </>
                )}
            </div>
        </div>
    );
}

export default RelNotasFiscal;