import http from '../helpers/http-common';
import {
	IQuizzCleanDTO,
	IQuizzDTO,
	IQuizzFullDTO
} from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useQuizService = () => {
	const resourceURL = 'learn-quiz';



	return {
		...baseFind<IQuizzCleanDTO, IQuizzDTO, IQuizzFullDTO>(resourceURL),
		...baseFindList<IQuizzCleanDTO, IQuizzDTO, IQuizzFullDTO>(resourceURL),
		...baseFindById<IQuizzCleanDTO, IQuizzDTO, IQuizzFullDTO>(resourceURL),
		...baseCreate<IQuizzCleanDTO, IQuizzDTO, IQuizzFullDTO>(resourceURL),
		...baseUpdate<IQuizzCleanDTO, IQuizzDTO, IQuizzFullDTO>(resourceURL),
		patch: (toUpdate: any): Promise<IAxiosResponseCustom<IQuizzCleanDTO>> =>
			http.patch(`${resourceURL}/${toUpdate.id}`, toUpdate),
		patchFull: (toUpdate: any): Promise<IAxiosResponseCustom<IQuizzCleanDTO>> =>
			http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),
	};
};
