import http from '../helpers/http-common';
import { IConteudoLearnCleanDTO, IConteudoLearnCustomDTO, IConteudoLearnDTO, IConteudoLearnFullDTO } from '../models/happy-code-api.model';
import { IAxiosResponseCustom } from '../models/http.model';
import { baseCreate } from './base/base-create.service';
import { baseFindById } from './base/base-find-id.service';
import { baseFindList } from './base/base-find-list.service';
import { baseFind } from './base/base-find.service';
import { baseUpdate } from './base/base-update.service';

export const useConteudoService = () => {
	const resourceURL = '/learn/v1/tbconteudo';

	const findbyIdCustom = (id: any): Promise<IAxiosResponseCustom<IConteudoLearnCustomDTO>> => http.get(`${resourceURL}/find-custom/${id}`);

	const createCustom = (body: any): Promise<IAxiosResponseCustom<IConteudoLearnCustomDTO>> => http.post(`${resourceURL}/create-custom`, body);

	const patchCustom = (body: any): Promise<IAxiosResponseCustom<IConteudoLearnCustomDTO>> => http.patch(`${resourceURL}/update-custom`, body);

	return {
		...baseFind<IConteudoLearnCleanDTO, IConteudoLearnDTO, IConteudoLearnFullDTO>(resourceURL),
		...baseFindList<IConteudoLearnCleanDTO, IConteudoLearnDTO, IConteudoLearnFullDTO>(resourceURL),
		...baseFindById<IConteudoLearnCleanDTO, IConteudoLearnDTO, IConteudoLearnFullDTO>(resourceURL),
		...baseCreate<IConteudoLearnCleanDTO, IConteudoLearnDTO, IConteudoLearnFullDTO>(resourceURL),
		...baseUpdate<IConteudoLearnCleanDTO, IConteudoLearnDTO, IConteudoLearnFullDTO>(resourceURL),
		patch: (toUpdate: any): Promise<IAxiosResponseCustom<IConteudoLearnCleanDTO>> => http.patch(`${resourceURL}/${toUpdate.id}`, toUpdate),
		patchFull: (toUpdate: any): Promise<IAxiosResponseCustom<IConteudoLearnCleanDTO>> => http.patch(`${resourceURL}/full/${toUpdate.id}`, toUpdate),
		findbyIdCustom,
		createCustom,
		patchCustom,
	};
};
