import { ExclamationCircleTwoTone } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import { Dropdown, Menu, Modal } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';
import { IInsigniaDTO } from '../../models/happy-code-api.model';
import { useInsigniaService } from '../../services/insignia.service';
import { useNotificationService } from '../../services/notification.service';

interface TItemLista {
	insignia: IInsigniaDTO;
}

const ItemListInsignia = memo(({ insignia }: TItemLista) => {
	const [ativo, setAtivo] = useState<boolean>();

	const notification = useNotificationService();
	const history = useHistory();
	const insigniaService = useInsigniaService();
	const { setIsGlobalLoading } = useGlobalContext();

	const goTo = () => {
		history.push(`insignia/${insignia?.id}/create`);
	};

	useEffect(() => {
		setAtivo(insignia?.ativo);
	}, [insignia?.ativo]);

	const showDeleteConfirm = () => {
		Modal.confirm({
			title: `Deseja realmente ${ativo ? 'suspender' : 'ativar'} a insígnia?`,
			icon: <ExclamationCircleTwoTone rev={undefined} />,
			content: insignia?.nome,
			okText: 'Sim',
			okType: `${ativo ? 'danger' : 'primary'}`,
			cancelText: 'Não',
			onOk: () =>
				insigniaService
					.patch({ id: insignia.id, ativo: !ativo })
					.then(() => {
						setAtivo(!ativo);
						notification({
							description: 'Insígnia alterada com sucesso!',
							type: 'success',
							message: 'Sucesso',
						});
					})
					.finally(() => setIsGlobalLoading(false)),
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			onCancel: () => {},
		});
	};

	const menu = (
		<Menu>
			<Menu.Item onClick={showDeleteConfirm}>
				<a rel="noopener noreferrer">Suspender/Ativar</a>
			</Menu.Item>
		</Menu>
	);

	console.log(insignia);

	return (
		<div className="registro-user" style={{ padding: 20 }} id="registro-financeiro">
			<div className="user" id="cursos" style={{ width: '10%' }}>
				<div className="nome">
					<p>{insignia?.id}</p>
					{ativo ? (
						<div>
							<div className="estado-user">
								<div className="dot-verde"></div>
								<p className="ativo">Ativo</p>
							</div>
						</div>
					) : (
						<div>
							<div className="estado-user">
								<div className="dot-vermelho"></div>
								<p className="inativo">Inativo</p>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="funcao-user" id="modulo-id" style={{ width: '15%' }}>
				<p>{insignia?.nome}</p>
			</div>
			<div className="funcao-user" id="conteudo-id" style={{ width: '20%', textAlign: 'center' }}>
				<p>{insignia?.habilidade?.descricao}</p>
			</div>
			<div className="funcao-user" id="cursos-modalidade" style={{ width: '13%' }}>
				<p>{insignia?.competencia?.descricao}</p>
			</div>
			{/* <div className="funcao-user" id="cursos-id" style={{ width: '14%' }}>
			<p>teste</p>
		</div> */}
			<div className="funcao-user" id="curso-id" style={{ width: '15%' }}>
				<p>{insignia?.notaCompetencia}</p>
			</div>
			<div className="funcao-user" id="cursos-modalidade" style={{ width: '15%' }}>
				<p>{insignia?.notaHabilidade}</p>
			</div>
			<div className="funcao-user" id="cursos-modalidade" style={{ width: '15%' }}>
				<p>{insignia?.coins}</p>
			</div>
			<div className="funcao-user" id="cursos-modalidade" style={{ width: '5%' }}>
				<Dropdown overlay={menu} placement="bottomRight" arrow>
					<Unicons.UilEllipsisH className="button-dropdown" size="18" color="#6A7C96" />
				</Dropdown>
			</div>

			<div className="registro-opcao" style={{ width: '5%' }} onClick={goTo}>
				<i className="row-user__action-cell">
					<Unicons.UilAngleRight size="32" className="action-cell__action-icon" />
				</i>
			</div>
		</div>
	);
});

ItemListInsignia.displayName = 'ItemListInsignia';

export default ItemListInsignia;
