import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewMenu from '../../components/NewMenu';
import { useCursoContext } from '../../context/CursoContext';
import { useGlobalContext } from '../../context/GlobalContext';

import { ILogCleanDTO, IQuizzDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useLogService } from '../../services/log.service';
import { useQuizService } from '../../services/quiz.service';
import FiltrosQuiz from './filtros-quiz';
import ItemListQuiz from './item-lista';
import './style.css';

export const Quiz: React.FunctionComponent = () => {
	const [data, setData] = useState<PageableResponse<IQuizzDTO>>();
	const [predicate] = useState<Predicate>(new Predicate());
	const quizService = useQuizService();
	const { setIsGlobalLoading } = useGlobalContext();

	const history = useHistory();
	const logService = useLogService();
	const [log] = useState<ILogCleanDTO>({
		descricaoEvento: 'Acesso Curso sede',
		descricaoTipo: 'Acesso a tela',
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true,
	});

	useEffect(() => {
		logService.registraLog(log);
	}, []);

	const findQuiz = () => {
		setIsGlobalLoading(true);
		quizService
			.find(predicate)
			.then(({ data }) => {
				setData(data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onPageChange = (event: ChangeEvent<any>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);
		findQuiz();
	};

	// const goToAdd = () => {
	//   history.push("/cursos/create/step-1");
	// }

	const ordenar = (campo) => {
		predicate.addSort(campo);
		findQuiz();
	};

	return (
		<div className="container">
			<NewMenu selecionado={210} />
			<div
				className="dashboard"
				style={{ paddingTop: 40, overflowX: 'scroll', height: '100vh' }}
			>
				<div className="box-title">
					<h3 className="title">
						Quizzes ({data?.totalElements ? data?.totalElements : 0})
					</h3>
					<Button
						className="button-primary"
						id="criar-c"
						icon={<PlusOutlined rev={undefined} />}
						onClick={() => history.push('quiz/create')}
					>
						Novo Quiz
					</Button>
				</div>
				<FiltrosQuiz findQuiz={findQuiz} predicate={predicate} />
				<div className="tabela">
					<div className="titles-users">
						<div className="item" style={{ width: '15%' }}>
							<span>ENUNCIADO</span>
							<div className="arrows" onClick={() => ordenar('dsEnunciado')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '18%' }}>
							<span>Alternativa 01</span>
							<div className="arrows" onClick={() => ordenar('dsAlternativa1')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '14%' }}>
							<span>Alternativa 02</span>
							<div className="arrows" onClick={() => ordenar('dsAlternativa2')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '14%' }}>
							<span>Alternativa 03</span>
							<div className="arrows" onClick={() => ordenar('dsAlternativa3')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '13%' }}>
							<span>Alternativa 04</span>
							<div className="arrows" onClick={() => ordenar('dsAlternativa4')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '9%' }}>
							<span>Idade Miníma</span>
							<div className="arrows" onClick={() => ordenar('nrIdadeMinima')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '10%' }}>
							<span>Idade Máxima</span>
							<div className="arrows" onClick={() => ordenar('nrIdadeMaxima')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '5%' }}></div>
					</div>
					<div className="tabela-body">
						{data &&
							data.content.map((quiz) => (
								<ItemListQuiz quiz={quiz} key={quiz.id} />
							))}
					</div>
					<div className="paginacao">
						<Pagination
							onChange={(event, page) => onPageChange(event, page - 1)}
							page={data ? data.pageable.pageNumber + 1 : 0}
							count={data ? data.totalPages : 0}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
