import { PlusOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewMenu from '../../components/NewMenu';
import { useGlobalContext } from '../../context/GlobalContext';

import { IConteudoLearnFullDTO, ILogCleanDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { PageableResponse } from '../../models/response.model';
import { useConteudoService } from '../../services/conteudo.service';
import { useLogService } from '../../services/log.service';
import FiltrosConteudo from './filtros-conteudos';
import ItemListConteudo from './item-lista';
import './style.css';

export const Conteudos: React.FunctionComponent = () => {
	const [data, setData] = useState<PageableResponse<IConteudoLearnFullDTO>>();
	const [predicate] = useState<Predicate>(new Predicate());
	const conteudoService = useConteudoService();
	const { setIsGlobalLoading } = useGlobalContext();

	const history = useHistory();
	const logService = useLogService();
	const [log] = useState<ILogCleanDTO>({
		descricaoEvento: 'Acesso Curso sede',
		descricaoTipo: 'Acesso a tela',
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true,
	});

	useEffect(() => {
		logService.registraLog(log);
	}, []);

	const findConteudo = () => {
		setIsGlobalLoading(true);
		conteudoService
			.findFull(predicate)
			.then(({ data }) => {
				setData(data);
				console.log(data);
			})
			.finally(() => setIsGlobalLoading(false));
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onPageChange = (event: ChangeEvent<any>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);
		findConteudo();
	};

	const ordenar = (campo) => {
		predicate.addSort(campo);
		findConteudo();
	};

	console.log(data);

	return (
		<div className="container">
			<NewMenu selecionado={201} />
			<div className="dashboard" style={{ paddingTop: 40, overflowX: 'scroll', height: '100vh' }}>
				<div className="box-title">
					<h3 className="title">Conteúdos ({data?.totalElements ? data?.totalElements : 0})</h3>
					<Button onClick={() => history.push('/conteudos/create')} className="button-primary" id="criar-conteudo" icon={<PlusOutlined rev={undefined} />}>
						Novo Conteúdo
					</Button>
				</div>
				<FiltrosConteudo findConteudo={findConteudo} predicate={predicate} />
				<div className="tabela">
					<div className="titles-users">
						<div className="item" style={{ width: '9%' }}>
							<span>ID</span>
							<div className="arrows" onClick={() => ordenar('id')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '19%' }}>
							<span>Título do contéudo</span>
							<div className="arrows" onClick={() => ordenar('conteudo')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>

						<div className="item" style={{ width: '20%' }}>
							<span>MÓDULO</span>
							<div className="arrows" onClick={() => ordenar('modulo')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						{/* <div className="item" style={{ width: '14%' }}>
							<span>ID CONTEUDO</span>
							<div className="arrows" onClick={() => ordenar('')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div> */}
						<div className="item" style={{ width: '12%' }}>
							<span>CURRÍCULO</span>
							<div className="arrows" onClick={() => ordenar('curriculo')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '14%' }}>
							<span>NEGÓCIO</span>
							<div className="arrows" onClick={() => ordenar('negocio')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '12%' }}>
							<span>TIPO DE CONTEÚDO</span>
							<div className="arrows" onClick={() => ordenar('tipoConteudo')}>
								<Unicons.UilDirection size="20" color="#92A9CB" />
							</div>
						</div>
						<div className="item" style={{ width: '5%' }}></div>
					</div>
					<div className="tabela-body">{data && data.content.map((conteudo) => <ItemListConteudo key={conteudo.id} conteudo={conteudo} />)}</div>
					<div className="paginacao">
						<Pagination
							onChange={(event, page) => onPageChange(event, page - 1)}
							page={data ? data.pageable.pageNumber + 1 : 0}
							count={data ? data.totalPages : 0}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
