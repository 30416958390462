import { Button } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Hand from '../../assets/img/dashboard/hand.svg';
import FondoLeft from '../../assets/img/dashboard/left.svg';
import FondoRigth from '../../assets/img/dashboard/rigth.svg';
import NewMenu from '../../components/NewMenu';

import { useGlobalContext } from '../../context/GlobalContext';

import CronogramaDiario from '../../components/CronogramaDiario';
import HomeListTop from '../../components/HomeListTop';
import IndicadoresHome from '../../components/IndicadoresHome';
import {
	ILogCleanDTO,
	IUnidadeHomeDTO
} from '../../models/happy-code-api.model';
import { useLogService } from '../../services/log.service';
import { useUnidadeService } from '../../services/unidade.service';
import './style.css';
import { TabSelectedEnum } from '../../enum/home.enum';
import MensagensComponent from './mensagens.component';
import CertificadoComponent from './certificado.component';

export const Franquia: React.FunctionComponent = () => {
	const { usuario, setIsGlobalLoading, token, unidade } = useGlobalContext();
	const history = useHistory();
	const logService = useLogService();
	const unidadeService = useUnidadeService();
	const [tabSelected, setTabSelected] = useState<TabSelectedEnum>(TabSelectedEnum.lead);
	const [home, setHome] = useState<IUnidadeHomeDTO>();

	const [log] = useState<ILogCleanDTO>({
		descricaoEvento: 'Acesso tela home franquia',
		descricaoTipo: 'Acesso a tela',
		idTipo: null,
		id: null,
		descricaoUsuario: null,
		ativo: true,
	});
	useEffect(() => {
		logService.registraLog(log);
	}, []);

	const getCurrentDate = () => {
		moment.locale('pt-br');
		const currentDate = moment().format(`DD MMMM YYYY, dddd`);
		return currentDate;
	};

	const findHome = async () => {
		if (unidade?.id) {
			return unidadeService
				.findHome(unidade?.id)
				.then(({ data }) => setHome(data));
		}
	};

	const acessoExternoCrm = () => {
		window.open(
			`${process.env.REACT_APP_HAPPYCODE_PUBLIC_URL_CRM}?t=${token?.access_token}&u=${unidade?.id}`,
			'_blank'
		);
	};

	const goToList = () => {
		if (tabSelected === TabSelectedEnum.lead) {
			acessoExternoCrm();
		}
		if (tabSelected === TabSelectedEnum.rematricula) {
			history.push(`/alunos`);
		}
		if (tabSelected === TabSelectedEnum.inadimplente) {
			history.push(`/financeiro`);
		}
	};

	useEffect(() => {
		setIsGlobalLoading(true);
		const requests = [findHome()];
		if (unidade?.id && usuario?.id) {
			Promise.all(requests)
				.finally(() => setIsGlobalLoading(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unidade.id, usuario.id]);





	return (
		<div className="container" style={{ backgroundColor: '#EBEFFC' }}>
			<NewMenu selecionado={1} />
			<div className="instrutor">
				<img src={FondoLeft} alt="" className="img-left" />
				<div className="fondo-rigth">
					<img src={FondoRigth} alt="" />
					<div className="fondo-blue"></div>
				</div>
				<div id="franquia" className="dashboard-instrutor">
					<div className="head">
						<div className="info" style={!unidade?.dtVencimento ? { width: '65%' } : {}}>
							<h4>
								<img src={Hand} /> Olá,{' '}
								<strong>{usuario && usuario?.usuario}</strong>
							</h4>
							<h6>Hoje</h6>
							<p>{getCurrentDate()}</p>
						</div>
						{unidade?.dtVencimento && (
							<CertificadoComponent unidade={unidade} />
						)}
						<MensagensComponent />
					</div>
					<div className="body-data">
						<div className="left" style={{ width: '70%' }}>
							<IndicadoresHome kpi={home?.kpi} contratoAtivo={home?.contratosAtivos} />

							<HomeListTop setTabSelected={setTabSelected} tabSelected={tabSelected} topListasHome={home?.topListasHome} />

							<Button
								style={{ width: '100%' }}
								className="button-primary"
								onClick={goToList}
							>
								Ver Todos
							</Button>
						</div>
						<div className="right" style={{ width: '28%' }}>
							<CronogramaDiario cronogramaAula={home?.cronogramaAula} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
